/* src/App.css */
/* Global Link Styles */
a {
  color: #0f91ff; /* Primary link color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold font for emphasis */
  padding: 5px; /* Small padding for clickable area */
  margin: 5px 0; /* Margin for spacing between links */
  transition: color 0.3s ease, background-color 0.3s ease; /* Smooth transitions */
}

/* Hover and Focus State */
a:hover,
a:focus {
  color: #ffffff; /* Change text color on hover */
  background-color: #0f91ff; /* Background color on hover */
  border-radius: 5px; /* Rounded corners for hover state */
  text-decoration: underline; /* Add underline on hover */
}

/* Additional Styles for ContactBox */
.contact-box a {
  font-size: 1.1em; /* Slightly larger font for emphasis */
}

/* Additional Link Styles for Dark Backgrounds */
.dark-background a {
  color: #0f91ff;
}

.dark-background a:hover,
.dark-background a:focus {
  color: #ffffff;
  background-color: #333333; /* Dark background on hover */
}



.container {
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
  padding: 20px;
  text-align: center;
}

.title-container {
  margin-bottom: 20px;
  color: gray; 
}

.title-image {
  width: 150px;
  height: 150px;
}

.contact-box {
  background-color: #e0e0e0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.disclaimer {
  background-color: #d0d0d0;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 0.9em;
}
/* InfoBox Component Styles */
.info-box {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.news-entry {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
}

.date-box {
  width: 20%;
  padding-right: 20px;
  text-align: left;
}

.date {
  font-weight: bold;
  color: #333;
}

.news-box {
  width: 80%;
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.news-content {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
